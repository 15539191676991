import $ from 'jquery';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useState, useMemo } from 'react';
import axios from 'axios';

function Maincomp(props){

  let [main_data, setMain_data] = useState();
  let login_key = props.login_key;
  const config = {
    headers : {
      'Authorization': `Bearer ${login_key}`
    }
  }

  useEffect(()=>{
    if(main_data){
      if(main_data.row.bgcolor_show == "Y"){
        document.documentElement.style.setProperty('--bgcolor', main_data.row.bgcolor);
        document.documentElement.style.setProperty('--transparent', (1 - (main_data.row.transparent / 100)));
      }
    }
  }, [main_data])

  useMemo(()=>{
    return axios.get(process.env.REACT_APP_API_KEY+'API/Main', config).then((result)=>{
      //console.log("메인 컴포넌트", result.data.RECORD);
      setMain_data(result.data.RECORD)
    });
  }, []);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
    };

    
  if(main_data){
    return(
      <div className='sections section_01'>
        {
          main_data.row.title_show == "Y" ?
            <div className='title_con'>
              <div dangerouslySetInnerHTML={{ __html : main_data.row.title  }} />
            </div>
          : null
        }
        <Slider {...settings}>
          {
            main_data.img == null ? 
            <div className='img_con'>
              <div style={{background : 'url(img/sec_02/img_sec2.jpg) center no-repeat', backgroundSize: 'cover'}}></div>
            </div>
            :
            main_data.img.map(function(a,i){
              return(
                <Main_slide data={main_data.img} key={i} val={i}></Main_slide>
              )
            })
          }
        </Slider>
      </div>
    )
  }
}

function Main_slide(props){
  let slide_data = props.data;
  return(
    <div className='img_con'>
      <div style={{background : 'url('+slide_data[props.val].full_url+') center no-repeat', backgroundSize: 'cover'}}></div>
    </div>
  )
}

export default Maincomp;
