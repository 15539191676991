import $ from 'jquery';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Routes, Route, Link, useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { change_page, replace_board, replace_data, replace_more_data } from '../store';
import axios from 'axios';

function chunk(params = [], size = 1) {
  const arr = [];
    
  for (let i = 0; i < params.length; i += size) {
    arr.push(params.slice(i, i + size));
  }

  return arr;
}

function Notice(props){
  
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let [notice_data, setNotice_data] = useState();
  let [board_filter, setFilter] = useState();
  let [copy_board, setCopy] = useState();
  let [select, setSelect] = useState(0);
  let login_key = props.login_key;
  const config = {
    headers : {
      'Authorization': `Bearer ${login_key}`
    }
  }
  useEffect(()=>{
    dispatch(change_page('notice on'));
    if(board_filter){
      let copy = [...board_filter];
      copy = chunk(copy, 8);
      setCopy(copy[0]);
    }
    if(notice_data){
      if(notice_data.img == null){
        document.documentElement.style.setProperty('--sec_bgurl', '#f5f5f5');
      }else{
        document.documentElement.style.setProperty('--sec_bgurl', 'url('+notice_data.img[0].full_url+')');
      }
      document.documentElement.style.setProperty('--sec_bgcolor', notice_data.row.bgcolor);
      document.documentElement.style.setProperty('--transparent', (1 - (notice_data.row.transparent / 100)));
    }
  }, [notice_data, board_filter]);

  useMemo(()=>{
    axios.get(process.env.REACT_APP_API_KEY+'API/Board/list?code=notice', config).then((result)=>{
      //console.log("공지&이벤트 게시판", result);
      setFilter(result.data.RECORD.rows);
    });
    axios.get(process.env.REACT_APP_API_KEY+'API/Notice', config).then((result)=>{
      //console.log("공지&이벤트 컴포넌트", result.data.RECORD);
      setNotice_data(result.data.RECORD);
    });
  }, []);
    
  let settings = {
    dots: false,
    infinite: false,
    draggable: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1530,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1
        }
      }
      ]
    };

  //console.log(notice_data);
  //console.log(copy_board);

  if(notice_data){
    return(
      <div className='sections section_02 slide'>
          <div className='width_con'>
              <div className='main_con'>
                  {/* <span className='btn_more' onClick={()=>{dispatch(replace_more_data('API/Board/list?code=notice')); navigate('/more_board');}}>게시판 더보기</span> */}
                  <h4>공지&이벤트</h4>
                  <ul>
                      <li onClick={()=>{
                          let copy = [...board_filter];
                          copy = chunk(copy, 8);
                          setCopy(copy[0]);
                          setSelect(0);
                      }} className={select == 0 ? 'on' : null}>전체</li>
                      <li onClick={()=>{
                          let copy = [...board_filter];
                          copy = chunk(copy, 8);
                          copy = copy[0].filter((a)=> a.board_type == 'notice');
                          setCopy(copy);
                          setSelect(1);
                      }} className={select == 1 ? 'on' : null}>공지사항</li>
                      <li onClick={()=>{
                          let copy = [...board_filter];
                          copy = chunk(copy, 8);
                          copy = copy[0].filter((a)=> a.board_type == 'event');
                          setCopy(copy);
                          setSelect(2);
                      }} className={select == 2 ? 'on' : null}>이벤트</li>
                  </ul>
              </div>
            <div className='board_con'>
                <ul>
                    {
                      copy_board == undefined ? null :
                        copy_board.map(function(a, i){
                          return(
                              <Boards data={copy_board} val={i} key={i}></Boards>
                          )
                        })
                    }
                </ul>
            </div>
            <div className='btn_con'>
              <span className='btn_more' onClick={()=>{
                    if(select === 0){
                      setCopy(board_filter);
                    }else if(select === 1){
                      let copy = [...board_filter];
                      copy = copy.filter((a)=> a.board_type == 'notice');
                      setCopy(copy);
                    }else if(select === 2){
                      let copy = [...board_filter];
                      copy = copy.filter((a)=> a.board_type == 'event');
                      setCopy(copy);
                    }
                    //console.log(select)
                    
                  }}>게시판 더보기</span>
            </div>
          </div>
      </div>
    )
  }
}

function Boards(props){
    let datas = props.data;
    let dispatch = useDispatch();
    return(
        <li>
            <div className='inner'>
              <h5 className={datas[props.val].board_type}>
                  <span>{datas[props.val].board_type == "notice" ? "공지" : "이벤트"}</span>
              </h5>
              <h3 className='board_title'>
                  {datas[props.val].board_title}
              </h3>
              <div className='desc_con'>
                <div dangerouslySetInnerHTML={{ __html : datas[props.val].board_content }} />
              </div>
            </div>
            <div className='btn_con'>
                <Link className='btn01' to="/view_board" onClick={()=>{
                  dispatch(replace_board(datas[props.val].board_no));
                  dispatch(replace_data(datas[props.val]));
                }}>VIEW MORE</Link>
                <Link to="/view_board" onClick={()=>{
                  dispatch(replace_board(datas[props.val].board_no));
                  dispatch(replace_data(datas[props.val]));
                }}><img src='/img/sec_02/next.png'></img></Link>
            </div>
        </li>
    )
}

export default Notice;